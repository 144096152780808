// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/ai-tuning/bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/ai-tuning/play-machine.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/images/ai-tuning/content-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".background-box[data-v-6f0d0635]{position:absolute;width:100%;height:100%;background-color:#0a161a;overflow-y:scroll;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%;background-repeat:no-repeat}.content-bg[data-v-6f0d0635],.play-machine[data-v-6f0d0635]{position:relative}.play-machine[data-v-6f0d0635]{width:5.56rem;height:5.76rem;transform:translateY(.64rem);z-index:2;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}.content-bg[data-v-6f0d0635]{width:7.5rem;height:7.24rem;transform:translateY(-.44rem);margin-bottom:-.44rem;padding-top:1.28rem;z-index:1;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:100%;background-repeat:no-repeat}.desc[data-v-6f0d0635]{box-sizing:border-box;position:relative;width:5.9rem;margin:0 auto .38rem;padding:.24rem .34rem;background:#2b6ea1;box-shadow:0 .04rem .2rem 0 rgba(0,0,0,.14);border-radius:.2rem;font-size:.3rem;font-family:PingFangSC-Semibold,PingFang SC;font-weight:700;color:#fff;line-height:.48rem;z-index:3}", ""]);
// Exports
module.exports = exports;
