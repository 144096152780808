<template>
  <div class="record-voice-box" ref="record-voice-box">
    <back-ground :desc="desc">
      <template v-if="status !== 3">
        <div class="record-icon" ref="record-btn" id="record-btn"></div>
        <p class="record-intro">按住录音</p>
      </template>
      <template v-else>
        <div class="play-panel-box" id="play-panel-box">
          <img class="avatar" src="@/assets/images/ai-tuning/avatar.png" id="avatar" />
          <sound-wave :is-play="isPlay" id="sound-wave" />
          <span class="countdown" id="countdown">{{ duration1 }}s</span>
        </div>
        <div class="btn-group">
          <aside class="btn" @click="handleChangeClick">
            <img class="btn-icon" src="@/assets/images/ai-tuning/change-icon.png" />
            <span class="btn-text">换一首</span>
          </aside>
          <aside class="btn" @click="handleControlClick">
            <img v-if="isPlay" class="btn-icon" src="@/assets/images/ai-tuning/pause-icon.png" />
            <img v-else class="btn-icon" src="@/assets/images/ai-tuning/play-icon.png" />
            <span class="btn-text">{{ isPlay ? '暂停' : '播放' }}</span>
          </aside>
        </div>
      </template>
    </back-ground>
    <div v-show="status === 2" ref="record-modal" class="record-modal-box">
      <div ref="slide-tip" class="slide-up-tip" :style="{ backgroundColor: enterCancel ? '#8793A8' : 'transparent' }">
        <p class="tip-text">{{ cancelTip }}</p>
        <img class="cancel-line" src="@/assets/images/ai-tuning/line.png" />
      </div>
      <div class="record-panel">
        <sound-wave :num="voiceNum" :is-play="isRecord" />
        <span class="countdown">{{ recordDuration }}s</span>
      </div>
      <div class="record-bg">
        <i class="record-status"></i>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <img class="loading-icon" src="@/assets/images/ai-tuning/loading.png" />
    </div>
    <div
      v-if="recOpenDialogShow"
      style="
        z-index: 99999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        background: rgba(0, 0, 0, 0.3);
      "
    >
      <div style="display: flex; height: 100%; align-items: center">
        <div style="flex: 1"></div>
        <div style="width: 2.4rem; background: #fff; padding: 0.15rem 0.2rem; order-radius: 0.1rem">
          <div style="padding-bottom: 0.1rem">录音功能需要麦克风权限，请允许；如果未看到任何请求，请点击忽略~</div>
          <div style="text-align: center"><a @click="waitDialogClick" style="color: #0b1">忽略</a></div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
    <audio ref="audio" controls style="visibility: hidden"></audio>
  </div>
</template>

<script>
  import BackGround from './components/BackGround';
  import SoundWave from './components/SoundWave';
  import API from '@/api';
  import { mapGetters } from 'vuex';

  const OSS = require('ali-oss');
  const parser = require('ua-parser-js');

  export default {
    async asyncData({ store, $http }) {
      const res = await $http.clientPost(API.OSS_AUTH_API, {
        type: 'audio',
      });
      console.log(res);
      store.commit('activity/aiTuning/setAccess', res);
    },
    components: { BackGround, SoundWave },
    data() {
      return {
        status: 1, // 1: 录音前; 2: 录音中; 3: 播放录音
        voiceNum: 1,
        ossAudioPath: '',
        getCount: 1,
        audio: null,
        loading: false,
        duration1: 0,
        isPlay: false,
        isRecord: false,
        playTimer: null,
        recordDuration: 12,
        recordTimer: null,
        slideTip: null,
        enterCancel: false,
        upload: false,
        touchTimeOut: null,
        type: 'wav',
        bitRate: 16,
        sampleRate: 44100,
        rec: 0,
        duration: 0,
        powerLevel: 0,
        recOpenDialogShow: 0,
        logs: [],
        isOpenRec: false,
      };
    },
    computed: {
      ...mapGetters('activity/aiTuning', ['access']),
      desc() {
        return this.status !== 3
          ? '清唱一段7-12秒钟的歌曲,体验快音用你的声音演唱的缘分歌曲,快来测测你的“音缘”吧~'
          : '已为您生成个性音乐，快来听听看吧～如果不喜欢，可以点击“换一首”重新生成哦～也分享给你的朋友试试看吧～';
      },
      ossClient() {
        console.log('expiration', this.access.expiration);
        console.log('stsToken', this.access.security_token);

        return new OSS({
          bucket: this.access.bucket_info.bucket,
          region: 'oss-cn-beijing',
          accessKeyId: this.access.access_key_id,
          accessKeySecret: this.access.access_key_secret,
          stsToken: this.access.security_token,
        });
      },
      cancelTip() {
        return this.enterCancel ? '松开取消录音' : '上划到此处取消录音';
      },
    },
    mounted() {
      console.log('Bowser => ', parser(navigator.userAgent));
      this.recOpen();

      this.audio = this.$refs['audio'];
      this.audio.addEventListener('loadedmetadata', () => {
        console.log('loadedmetadata 触发，音乐准备就绪～');
        console.log('audio', this.audio);
        this.duration1 = this.audio.duration;
        console.log('duration1', this.duration1);
        this.audio.play();
        this.isPlay = true;
        this.startPlayCountDown();
      });
      this.audio.addEventListener('ended', () => {
        console.log('play ended');
        this.isPlay = false;
        this.clearPlayTimer();
        this.duration1 = this.audio.duration;
      });

      const recordEle = document.querySelector('#record-btn');
      this.slideTip = this.$refs['slide-tip'];

      recordEle.addEventListener(
        'touchstart',
        (e) => {
          console.log('触摸开始～', e);
          e.preventDefault();
          e.stopPropagation();

          if ('record-btn' === e.target.id) {
            if (this.recStart()) {
              this.status = 2;
              this.recordDuration = 12;
              this.voiceNum = 1;
              this.isRecord = true;
              this.upload = false;
              this.recordCountDown();
              this.audio.play().catch((e) => {
                console.log(e);
                this.audio.pause();
              });
            } else {
              this.$toast(this.logs[this.logs.length - 1].msg);
            }
          }

          if (
            'play-panel-box' === e.target.id ||
            'avatar' === e.target.id ||
            'sound-wave' === e.target.id ||
            'countdown' === e.target.id ||
            'wave-item' === e.target.className
          ) {
            this.handleControlClick();
          }
        },
        false
      );

      recordEle.addEventListener(
        'touchend',
        (e) => {
          console.log('触摸结束～', e);
          e.stopPropagation();

          if ('record-btn' === e.target.id) {
            if (!this.enterCancel) {
              if (this.isOpenRec) {
                if (this.recordDuration > 5) {
                  this.$toast('录音太短，至少需要 7 秒的录音~');
                } else if (!this.upload) {
                  this.uploadAudio();
                }
              }
            } else {
              this.enterCancel = false;
            }

            this.status = 1;
            console.log('status', this.status);
            this.clearRecordTimer();
          }
        },
        false
      );

      recordEle.addEventListener('touchmove', (e) => {
        e.preventDefault();
        this.handleTouchMove(e);
      });
    },
    methods: {
      recOpen() {
        const This = this;
        const rec = (this.rec = this.$recorder.Recorder({
          type: This.type,
          bitRate: This.bitRate,
          sampleRate: This.sampleRate,
          onProcess: function (buffers, powerLevel, duration) {
            This.duration = duration;
            This.powerLevel = powerLevel;
          },
        }));

        This.dialogInt = setTimeout(function () {
          //定时8秒后打开弹窗，用于监测浏览器没有发起权限请求的情况
          This.showDialog();
        }, 8000);

        rec.open(
          function () {
            This.isOpenRec = true;
            This.dialogCancel();
            This.reclog('已打开:' + This.type + ' ' + This.sampleRate + 'hz ' + This.bitRate + 'kbps', 2);
          },
          function (msg, isUserNotAllow) {
            This.isOpenRec = false;
            This.dialogCancel();
            This.reclog((isUserNotAllow ? 'UserNotAllow，' : '') + '打开失败：' + msg, 1);
          }
        );
      },
      recStart() {
        if (!this.isOpenRec) {
          this.reclog('开始录音失败，未打开录音', 1);
          return false;
        }

        this.rec.start();
        const set = this.rec.set;
        this.reclog('录制中：' + set.type + ' ' + set.sampleRate + 'hz ' + set.bitRate + 'kbps');

        return true;
      },
      recStop() {
        const This = this;
        const rec = this.rec;
        This.rec = null;

        return new Promise((resolve, reject) => {
          if (!This.isOpenRec) {
            This.reclog('结束录音失败，未打开录音', 1);
            reject({
              type: 'record',
              message: '未打开录音',
            });
          }
          rec.stop(
            function (blob, duration) {
              This.reclog('已录制:', '', {
                blob: blob,
                duration: duration,
                rec: rec,
              });
              resolve({ blob, duration });
            },
            function (s) {
              This.reclog('结束出错：' + s, 1);
              reject({
                type: 'record',
                message: `录音结束出错：${s}`,
              });
            },
            true
          ); // 自动close
        });
      },
      reclog(msg, color, res) {
        this.logs.splice(0, 0, {
          idx: this.logs.length,
          msg: msg,
          color: color,
          res: res,
          playMsg: '',
          down: 0,
          down64Val: '',
        });
      },
      showDialog() {
        //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调
        if (!/mobile/i.test(navigator.userAgent)) {
          return; //只在移动端开启没有权限请求的检测
        }
        this.recOpenDialogShow = 1;
      },
      dialogCancel() {
        clearTimeout(this.dialogInt);
        this.recOpenDialogShow = 0;
      },
      waitDialogClick() {
        this.dialogCancel();
        this.reclog('打开失败：权限请求被忽略，用户主动点击的弹窗', 1);
        this.$toast(`打开录音失败：权限请求被忽略`);
      },
      uploadAudio() {
        this.status = 1;
        this.loading = true;

        this.recStop()
          .then(async ({ blob }) => {
            const fileName = `${this.access.bucket_info.dir}/${new Date().getTime()}wav_syn.wav`;

            return this.ossClient.put(fileName, blob);
          })
          .then(({ url }) => {
            console.log('上传资源 oss url~', url);

            const matches = /https:\/\/.*\/(resource\/\d+wav_syn\.wav)/gi.exec(url);
            this.ossAudioPath = matches[1];

            // 用户客户端信息音频数据埋点
            const ua = parser(navigator.userAgent);
            const params = {
              page_title: 'ai音乐',
              element_name: 'ai音乐打开方式',
              remarks: `${ua.browser.name}/${ua.browser.version};${ua.device.vendor};${ua.device.model};${ua.engine.name}/${ua.engine.version};${url}`,
            };
            this.$track(params);

            return this.getAIAudio();
          })
          .then(({ timbre_url }) => {
            console.log('合成音乐 timbre_url~', timbre_url);

            this.status = 3;
            this.playAIAudio(timbre_url);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log('录音合成失败：', err);

            if (err && err.type === 'record') {
              this.$toast(err.message);
            } else if (err && err.message && err.message.indexOf('timeout') !== -1) {
              this.$toast('请求超时');
            } else {
              this.$toast('录音合成失败');
            }
          });
      },
      getAIAudio() {
        return this.$http.TaskPost(API.GET_AI_AUDIO_API, {
          t_wav: this.ossAudioPath,
          request_count: this.getCount,
        });
      },
      playAIAudio(url) {
        console.log('给 audio 赋值请求音频数据~');
        this.audio.src = url;
      },
      handleChangeClick() {
        this.loading = true;
        this.getCount += 1;

        this.getAIAudio()
          .then(({ timbre_url }) => {
            console.log('changed timbre_url~', timbre_url);
            this.playAIAudio(timbre_url);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            if (err && err.message && err.message.indexOf('timeout') !== -1) {
              this.$toast('请求超时');
            }
          });
      },
      handleControlClick() {
        console.log('click 事件触发');
        if (this.audio.paused || this.audio.ended) {
          this.audio.play();
          this.isPlay = true;
          this.startPlayCountDown();
        } else {
          this.audio.pause();
          this.isPlay = false;
          this.clearPlayTimer();
        }
      },
      startPlayCountDown() {
        if (this.playTimer) {
          this.clearPlayTimer();
        }

        this.playTimer = setInterval(() => {
          if (!this.duration1) {
            this.clearPlayTimer();
            return;
          }

          this.duration1 -= 1;
        }, 1000);
      },
      clearPlayTimer() {
        clearInterval(this.playTimer);
        this.playTimer = null;
      },
      recordCountDown() {
        if (this.recordTimer) {
          this.clearRecordTimer();
        }

        this.recordTimer = setInterval(() => {
          if (!this.recordDuration) {
            this.uploadAudio();
            this.upload = true;
            this.clearRecordTimer();
            return;
          }

          this.recordDuration -= 1;
          this.voiceNum += 2;
        }, 1000);
      },
      clearRecordTimer() {
        clearInterval(this.recordTimer);
        this.recordTimer = null;
        this.isRecord = false;
      },
      handleTouchMove(event) {
        const { clientY: y } = event.touches[0];
        const { bottom } = this.slideTip.getBoundingClientRect();

        if (y <= bottom) {
          this.enterCancel = true;
        } else {
          this.enterCancel = false;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .record-voice-box {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .record-icon {
    position: relative;
    z-index: 4;
    width: 1.96rem;
    height: 1.96rem;
    margin: 0 auto;
    background-image: url('../../../assets/images/ai-tuning/record-icon.png');
    background-size: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .record-intro {
    position: relative;
    z-index: 4;
    font-size: 0.36rem;
    line-height: 0.36rem;
    text-align: center;
  }
  .avatar {
    width: 0.7rem;
    border-radius: 0.35rem;
  }
  .play-panel-box,
  .record-panel {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    width: 5.9rem;
    max-height: 0.8rem;
    padding: 0.14rem 0.32rem 0.14rem 0.24rem;
    border-radius: 0.76rem;
    z-index: 4;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .record-panel:after {
    content: '';
    position: absolute;
    bottom: -0.19rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0.03rem;
    height: 0.03rem;
    background-color: transparent;
    border-top: 0.2rem solid #8692a8;
    border-left: 0.15rem solid transparent;
    border-right: 0.15rem solid transparent;
    z-index: 3;
  }
  .play-panel-box {
    justify-content: space-between;
    margin: 0.4rem auto 0.42rem;
    background-color: #286da2;
    box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.14);
  }
  .record-panel {
    position: absolute;
    bottom: 6.2rem;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    background: linear-gradient(219deg, #667aa7 0%, #999fa4 100%);
    box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.2);
    .sound-wave-box,
    .stop-wave-box {
      flex: 1;
    }
  }
  .countdown {
    font-size: 0.3rem;
    line-height: 0.48rem;
  }
  .record-intro,
  .countdown,
  .btn-text {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
  .btn-group {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    text-align: center;
  }
  .btn:first-of-type {
    margin-right: 1.42rem;
  }
  .btn-icon {
    width: 0.66rem;
    margin: 0 auto;
  }
  .btn-text {
    margin-top: 0.13rem;
    font-size: 0.28rem;
    line-height: 0.5rem;
  }
  .record-modal-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .record-modal-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
  }
  .slide-up-tip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 7.35rem;
    padding: 0.3rem 0.3rem 0.2rem;
    border-radius: 0.1rem;
  }
  .record-bg {
    position: absolute;
    bottom: 0;
    z-index: 6;
    width: 100%;
    height: 5.48rem;
    background-image: url('../../../assets/images/ai-tuning/record-bg.png');
    background-size: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .record-status {
    position: absolute;
    top: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 7;
    width: 1.96rem;
    height: 1.96rem;
    background-image: url('../../../assets/images/ai-tuning/record-status.png');
    background-size: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .cancel-line {
    position: relative;
    width: 3.04rem;
  }
  .tip-text {
    margin-bottom: 0.18rem;
    font-size: 0.32rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.32rem;
    text-align: center;
  }
  .start-btn,
  .end-btn,
  .play-btn {
    position: relative;
    z-index: 5;
    display: block;
    width: 2rem;
    height: 0.56rem;
    margin: 0 auto 20px;
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 8;
  }
  .loading-icon {
    width: 0.88rem;
    animation: loading 500ms linear infinite;
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
