<template>
  <ul v-if="isPlay" class="sound-wave-box">
    <li class="wave-item" v-for="(item, index) in lineArray" :key="index" :style="{ height: getHeight(item) }"></li>
  </ul>
  <ul v-else class="stop-wave-box">
    <li class="wave-item" v-for="(item, index) in lineArray" :key="index" :style="{ height: getHeight(item) }"></li>
  </ul>
</template>

<script>
  const heightList = [2, 5, 8, 26, 18, 11, 14, 20, 9];

  export default {
    props: {
      num: {
        type: Number,
        default: 40,
      },
      isPlay: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      getHeight() {
        const len = heightList.length;
        return function (index) {
          return `${heightList[index % (len - 1)]}px`;
        };
      },
      lineArray() {
        return Array.from({ length: this.num }, (v, i) => i);
      },
    },
  };
</script>

<style lang="less" scoped>
  .sound-wave-box,
  .stop-wave-box {
    display: flex;
    justify-content: center;
    align-items: center;
    // animation: wave 1s ease-in-out infinite;
    // max-width: 3.8rem;
    // overflow-x: hidden;
  }
  .wave-item {
    flex-shrink: 0;
    width: 0.04rem;
    height: 0.52rem;
    margin: 0 0.02rem;
    border-radius: 0.04rem;
    background-color: #ffffff;
  }
  .sound-wave-box {
    .wave-item:nth-of-type(2n) {
      animation: wave2n 700ms ease-in-out infinite alternate;
    }
    .wave-item:nth-of-type(2n + 1) {
      animation: wave2n1 500ms ease-in-out infinite alternate;
    }
    .wave-item:nth-of-type(3n) {
      animation: wave2n2 300ms ease-in-out infinite alternate;
    }
  }
  @keyframes wave2n {
    100% {
      height: 0.32rem;
    }
  }
  @keyframes wave2n1 {
    100% {
      height: 0.52rem;
    }
  }
  @keyframes wave2n2 {
    100% {
      height: 0.22rem;
    }
  }
</style>
