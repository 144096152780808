<template>
  <div class="background-box">
    <!--<img class="bg" src="@/assets/images/ai-tuning/bg.png" />-->
    <div class="play-machine"></div>
    <!--<img class="play-machine" src="@/assets/images/ai-tuning/play-machine.png" />-->
    <!--<img class="content-bg" src="@/assets/images/ai-tuning/content-bg.png" />-->
    <div class="content-bg">
      <p class="desc">{{ desc }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      desc: String,
    },
  };
</script>

<style lang="less" scoped>
  .background-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0a161a;
    overflow-y: scroll;
    background-image: url('../../../../assets/images/ai-tuning/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .play-machine,
  .content-bg {
    position: relative;
  }
  .play-machine {
    width: 5.56rem;
    height: 5.76rem;
    transform: translateY(0.64rem);
    z-index: 2;
    background-image: url('../../../../assets/images/ai-tuning/play-machine.png');
    background-size: cover;
  }
  .content-bg {
    width: 7.5rem;
    height: 7.24rem;
    transform: translateY(-0.44rem);
    margin-bottom: -0.44rem;
    padding-top: 1.28rem;
    z-index: 1;
    background-image: url('../../../../assets/images/ai-tuning/content-bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .desc {
    box-sizing: border-box;
    position: relative;
    width: 5.9rem;
    margin: 0 auto 0.38rem;
    padding: 0.24rem 0.34rem;
    background: #2b6ea1;
    box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.14);
    border-radius: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.48rem;
    z-index: 3;
  }
</style>
